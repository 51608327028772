<template>
  <div class="redEnvelopesBg">
    <div class="preLoad"></div>
    <div v-if="redStates">
      <img src="../../assets/redEnvelopes/bgz.jpg" alt="" />
      <div class="redEnvelopesBox">
        <p class="redEnvelopesTips" v-html="tips"></p>
        <!-- 开红包前 -->
        <div class="redEnvelopesBorder" v-show="openRed">
          <img src="../../assets/redEnvelopes/red.png" alt="" />
          <div class="redEnvelopesOpen">
            <img src="../../assets/redEnvelopes/kai.png" alt="" />
          </div>
        </div>
        <!-- 恭喜您中得xx元 -->
        <div class="redEnvelopesresult" v-show="openRedSuccess">
          <img src="../../assets/redEnvelopes/get-red.png" alt="" />
          <div class="edEnvelopesMoney">
            <p class="congratulations">恭喜您中得</p>
            <p>
              <span class="money">{{ money }}</span> <span class="unit">元</span>
            </p>
          </div>
        </div>
        <!-- 红包已发放 -->
        <div class="redEnvelopesresult" v-show="openRedRepeat">
          <img src="../../assets/redEnvelopes/get-red.png" alt="" />
          <div class="edEnvelopesMoney">
            <p class="issued">您的红包已发放</p>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <img src="../../assets/redEnvelopes/bgError.png" alt="" />
      <div class="redEnvelopesBox">
        <div class="errorTips">
          <img src="../../assets/redEnvelopes/red.png" alt="" />
          <div class="edEnvelopesMoney">
            <p class="errorText">{{ errorText }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { receiveRedPacket } from '@/api/index.js'
import setting from '@/setting'

export default {
  name: 'authorize',
  data() {
    return {
      // tips: "恭喜您获得红包一个</br>点击“开”打开红包",
      tips: '恭喜您获得红包一个',
      openRed: true, // 是否点击开红包
      money: '00.00',
      redStates: true, // 红包发放状态，成功||失败
      isOpen: true, // 防重点击
      openRedRepeat: false,
      openRedSuccess: false,
      errorText: ''
    }
  },
  methods: {
    openAxjx() {
      if (this.isOpen) {
        this.isOpen = false
        receiveRedPacket({
          prizeId: this.$route.query.prizeId,
          drawLogId: this.$route.query.drawLogId
        })
          .then(({ data }) => {
            let tips =
              '恭喜您获得红包，将于24小时之内到账</br>请返回微信领取红包'
            if (data.status == 200) {
              this.openRed = false
              this.openRedSuccess = true
              this.money =
                setting.redEnvelopeApiType == 'DouMi'
                  ? data.unit_money
                  : data.data.unit_money
              this.tips = tips
            } else if (data.status == 400001) {
              // 红包已领取，请勿重新领取！
              this.openRed = false
              this.openRedRepeat = true
              this.tips = tips
            } else {
              this.redStates = false
              this.errorText = data.msg
            }
            this.isOpen = true
          })
          .catch(() => {
            this.isOpen = true
          })
      }
    },
    returnBtn() {
      this.$router.go(-1)
    }
  },
  created() {
    this.openAxjx() // 省去开的步骤，自动开奖
  }
}
</script>

<style lang="less" scoped>
.preLoad {
  position: absolute;
  left: -9999px;
  top: 0;
  width: 0;
  height: 0;
  background-image: url('../../assets/redEnvelopes/get-red.png');
}


.redEnvelopesBg {
  background: #c5000f;
  width: 100%;
  height: 100%;
}

.redEnvelopesBox {
  position: absolute;
  top: 272px;
  left: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 750px;
}


.redEnvelopesTips {
  width: 692px;
  background: rgba(136, 33, 10, 0.3);
  text-align: center;
  height: 134px;
  font-size: 34px;
  color: #FCEE98;
  margin: 0 auto;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 34px;

}

.redEnvelopesBorder {
  position: relative;
  margin-top: -15px;
}

.redEnvelopesresult {
  width: 750px;
  margin-top: -15px;
}


.edEnvelopesMoney {
  position: absolute;
  top: 400px;
  left: 0;
  font-size: 40px;
  width: 100%;
  text-align: center;
  color: #fff576;
  height: 250px;

  .congratulations {
    color: #FFF576;
    font-size: 46px;
    margin-bottom: 30px;
  }

  // 红包发放成功
  .money {
    margin-right: 6px;
    font-size: 103px;
    text-shadow: #fff 4px 0 0, #fff 0 4px 0, #fff -4px 0 0, #fff 0 -4px 0;
    color: red;
  }

  .unit {
    font-size: 50px;
  }


  // 红包已经发放
  .issued {
    color: #FFF576;
    font-size: 36px;
    margin-top: 60px;
  }
}

.redEnvelopesOpen {
  position: absolute;
  top: 220px;
  width: 124px;
  left: 313px;
  animation: move 0.8s 0s infinite;
  transform-origin: bottom;
}

.callBack {
  width: 417px;
  margin: 20px auto 0;
}

@keyframes move {
  0% {
    transform: scale(1.1);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}



// 红包发放失败
.errorTips {
  width: 750px;
  margin-top: 60px;

  .edEnvelopesMoney {
    top: 390px;

    .errorText {
      width: 410px;
      margin: 0 auto;
      font-size: 34px;
      text-align: center;
      color: #FCEE98;
      font-size: 34px;
    }
  }
}
</style>
